<!-- 大乐透基本走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1629609108732" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2399" width="45" height="45">
                        <path d="M452.846933 52.986311h117.771378l-127.724089 392.436622h-117.543822z" fill="#322C2B"
                            p-id="2400"></path>
                        <path d="M29.641956 446.839467l36.189866-111.889067 333.969067 242.591289-36.420267 111.741155z"
                            fill="#0C7CBE" p-id="2401"></path>
                        <path d="M273.965511 971.013689l-95.274667-69.290667 333.7216-242.212978 95.379912 69.048889z"
                            fill="#EFB918" p-id="2402"></path>
                        <path d="M847.798044 900.616533l-95.277511 69.1456-127.374222-392.220444 95.152356-69.304889z"
                            fill="#14934A" p-id="2403"></path>
                        <path d="M958.196622 333.348978l36.161422 111.746844H581.808356l-36.411734-111.746844z"
                            fill="#D7282A" p-id="2404"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国体育彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>大 乐 透 周 一 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a">期号</th>
                        <th rowspan="2" class="ranks_a1">星期</th>
                        <th colspan="12" class="ranks_b">一区</th>
                        <th colspan="12" class="ranks_b">二区</th>
                        <th colspan="11" class="ranks_b">三区</th>
                        <th colspan="12" class="ranks_b">后区</th>
                        <th rowspan="2" class="ranks_a1">和值</th>
                        <th rowspan="2" class="ranks_a1">跨度</th>
                        <th rowspan="2" class="ranks_a1">AC值</th>
                        <th rowspan="2" class="ranks_b">奇偶</th>
                        <th rowspan="2" class="ranks_b">三区比</th>
                    </tr>
                    <tr>
                        <th class="ranks_c" v-for="(item , i) in 35" :key="i">{{item}}</th>
                        <th class="ranks_d" v-for="(item , i) in 12" :key="i">{{item}}</th>


                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item2 ,x) in dlt" :key='x'>
                        <td>{{item2.qihao}}</td>
                        <td>{{item2.xq}}</td>
                        <!-- 前区走势 -->
                        <td class="b-red" v-for="(item ,i) in red" :key='i'>
                            <div :class="item1==item ? 'q-red' : 'no'" v-for="(item1 ,j) in red0[x]" :key='j'>
                                {{item1==item ? item : ''}}
                            </div>
                        </td>
                        <!-- 后区走势 -->
                        <td class="b-lan" v-for="(item ,i) in lan" :key='i'>
                            <div :class="item==item1 ? 'q-lan' : 'no'" v-for="(item1 ,j) in lan0[x]" :key='j'>
                                {{item==item1 ? item : ''}}
                            </div>
                        </td>
                        <!-- 和值 -->
                        <td>{{item2.data[0]+item2.data[1]+item2.data[2]+item2.data[3]+item2.data[4]}}</td>
                        <!-- 跨度 -->
                        <td>{{item2.data[4]-item2.data[0]}}</td>
                        <!-- ac值  -->
                        <td>{{ac[x]}}</td>
                        <!-- 奇偶比 -->
                        <td>{{item2.data[0]%2+item2.data[1]%2+item2.data[2]%2+item2.data[3]%2+item2.data[4]%2}}:{{5-(item2.data[0]%2+item2.data[1]%2+item2.data[2]%2+item2.data[3]%2+item2.data[4]%2)}}
                        </td>
                        <!-- 三区比 -->
                        <td>
                            <div style="display:inline-block" v-for="(item ,i) in sqb[x]" :key='i'>
                                {{item}}:
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 53" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 53" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 53" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'dlt-monday',
        components: {

        },
        data() { // 数据源
            return {
                red: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35],
                red0: [],
                lan: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                lan0: [],
                ac: [],
                sqb: []

            }
        },
        mounted() {
            this.mac()
            this.mred()
        },
        computed: {
            dlt() {
                return this.$store.state.dlt.filter((dlt) => {
                    return dlt.xq == 1;
                })
            },
           
        },
        methods: { // 函数体部分，js的主要逻辑控制  
            mred() {
                for (var i = 0; i < this.dlt.length; i++) {
                    var arr = []
                    var arr2 = []
                    var arr3 = []
                    var a = 0
                    var b = 0
                    var c = 0
                    // 前区号码
                    for (var j = 0; j < this.dlt[i].data.length - 2; j++) {
                        arr.push(this.dlt[i].data[j])
                        // 三区比
                        if (this.dlt[i].data[j] < 13) {
                            a += 1
                        } if (this.dlt[i].data[j] > 12 && this.dlt[i].data[j] < 25) {
                            b += 1
                        } if (this.dlt[i].data[j] > 24) {
                            c += 1
                        }
                        // arr3.push(a,b,c)
                    }
                    // 后区号码
                    for (var y = this.dlt[i].data.length - 2; y < this.dlt[i].data.length; y++) {
                        arr2.push(this.dlt[i].data[y])
                    }

                    this.red0.push(arr)
                    this.lan0.push(arr2)
                    arr3.push(a, b, c)
                    this.sqb.push(arr3)
                    //  console.log(this.sqb)
                }
            },
            mac() {//AC值
                for (var i = 0; i < this.dlt.length; i++) {
                    var arr = []
                    for (var j = 0; j < this.dlt[i].data.length - 1; j++) {
                        for (var y = j; y < 4; y++) {
                            arr.push(Math.abs(this.dlt[i].data[j] - this.dlt[i].data[y + 1]))
                        }
                    }
                    var newArr = [];
                    for (var x = 0; x < arr.length; x++) {
                        if (newArr.indexOf(arr[x]) == -1) {
                            newArr.push(arr[x]);
                        }
                    }
                    this.ac.push((newArr.length - (5 - 1)) % 10)
                }
            },
        },
        props: {
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: rgb(224, 202, 5);
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a {
        width: 60px;
    }

    .ranks_a1 {
        width: 25px;
    }

    .ranks_c {
        width: 23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }

    .b-red {
        height: 23px;
        background-color: rgb(221, 201, 171);

    }

    .b-lan {
        background-color: rgb(184, 200, 230);
    }

    .q-red {
        background-color: rgb(158, 29, 147);
        border-radius: 15px;
        color: #fff;
    }

    .q-lan {
        background-color: rgb(212, 164, 3);
        color: #fff;
        border-radius: 15px;
    }

    .no {
        display: none;
    }
</style>